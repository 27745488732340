import React, { useState } from 'react'
import Container from '../components/Container'
import SEO from '../components/SEO'
import { Flex, Box } from 'reflexbox'
import Card from '../components/Card'
import Button from '../components/Button'
import Badge from '../components/Badge'
import axios from 'axios'

const TermsOfUse = props => {
  let [license, setLicense] = useState('')
  let [buttonState, setButtonState] = useState(false)
  let [statusMessage, setStatusMessage] = useState('')
  let [statusCode, setStatusCode] = useState('')

  const handleChangeLicense = e => {
    setLicense(e.target.value)
  }

  const handleSubmit = event => {
    axios
      .post(`/.netlify/functions/reset-license`, {
        license: license
      })
      .then(res => {
        if (res.status === 200) {
          setStatusMessage('Your license was successfully reset')
          setStatusCode(200)
          setButtonState(false)
        }
      })
      .catch(function(error) {
        setButtonState(false)
        if (error.response.status === 404) {
          setStatusCode(400)
          setStatusMessage('The license you entered was not found.')
        }
      })

    setButtonState(true)
    setStatusMessage('')
    setStatusCode('')
    event.preventDefault()
  }

  return (
    <>
      <SEO title="Reset license" />

      {/* Hero */}
      <Container my={[4, 5]}>
        <Flex flexWrap="wrap" justifyContent="center">
          <Box width={[1, 1 / 2]} px={[0, 3]}>
            <Card p={4}>
              <h2>Reset IconJar v1 license</h2>
              <p>
                You can reset your IconJar v1 license if you run out of your
                activation quota. Licenses can be used to activate two Macs, and
                resetting your license deactivates the oldest install.
              </p>
              <Badge colorscheme="red">This only works for IconJar v1</Badge>
              <form onSubmit={handleSubmit} id="upgrade-form">
                <Box width={1} pt={4} mb={3}>
                  <label htmlFor="licenseInput">License key</label>
                  <input
                    type="text"
                    id="licenseInput"
                    placeholder="id11234567891odr"
                    value={license}
                    onChange={handleChangeLicense}
                  />
                </Box>
                {setStatusCode !== '' && (
                  <p
                    style={{
                      color: statusCode === 200 ? '#77CA34' : '#ee5253'
                    }}>
                    {statusMessage}
                  </p>
                )}
                <Button
                  value="submit"
                  form="upgrade-form"
                  role="button"
                  showprogress={buttonState}
                  disabled={buttonState}
                  big
                  stretched>
                  Reset license key
                </Button>
              </form>
            </Card>
          </Box>
        </Flex>
      </Container>
    </>
  )
}

export default TermsOfUse
